import singleSpaHtml from 'single-spa-html' // single-spa lifecycles helper
import template from './template.html' // separate html template provides better syntax highlighting

const locales = {
  ru: {
    label: 'Разблокировать мои',
    unblocked: 'Все разблокировано',
    error: 'Возникла ошибка',
  },
}

const htmlLifecycles = singleSpaHtml({
  domElementGetter: () => {
    container = document.createElement('div')
    container.classList.add(
      'single-spa-application:widget-vanilla-unblock-all-cameras',
    )
    document.body.prepend(container) // single-spa automatically _appends_, but this content should be _prepended_ for accessibility
    return container
  },
  template: () => template,
})

export const mount = async (props) => {
  await htmlLifecycles.mount(props) // wait for single-spa to mount the application
  const locale = locales.ru
  const widgetProps = props.props
  const button = props.domElement.querySelector('section > button')
  const label = props.domElement.querySelector(
    'section > button > span.p-button-label',
  )
  const icon = props.domElement.querySelector(
    'section > button > span.p-button-icon',
  )
  label.textContent = locale.label
  if (!button) return
  const apiRepo = await System.import('@netvision/lib-api-repo')
  const api = apiRepo.repositoryGetter(
    widgetProps.lib || {
      name: 'lib-api',
      mixins: ['cubejs'],
    },
  )
  const { userId } = await api.getUserInfo()
  button.addEventListener('click', async () => {
    icon.classList.add('mdi-spin', 'mdi-loading', 'p-button-icon-left')
    try {
      const { results: cameraLockings } = await api.getEntitiesList({
        limiter: {
          type: 'CameraLocking',
        },
        filter: {
          q: [
            {
              key: 'state',
              operator: '==',
              value: 'Active,Creation',
            },
            {
              key: 'ownerId',
              operator: '==',
              value: userId || '',
            },
          ],
        },
      })
      if (cameraLockings?.length) {
        for (const cameraLocking of cameraLockings) {
          await api.updateEntity({
            type: 'Camera',
            id: cameraLocking.cameraId,
            unlockStreams: {},
          })
        }
      }

      label.textContent = locale.unblocked
      icon.classList.remove('mdi-spin', 'mdi-loading')
      icon.classList.add('mdi-check')
      button.classList.add('p-button-success')
      setTimeout(() => {
        icon.classList.remove('mdi-check', 'p-button-icon-left')
        button.classList.remove('p-button-success')
        label.textContent = locale.label
        widgetProps.refrash && widgetProps.refrash()
      }, 3000)
    } catch (error) {
      label.textContent = locale.error
      icon.classList.remove('mdi-spin', 'mdi-loading')
      icon.classList.add('mdi-close')
      button.classList.add('p-button-danger')
      setTimeout(() => {
        label.textContent = locale.label
        icon.classList.remove('mdi-close', 'p-button-icon-left')
        button.classList.remove('p-button-danger')
      }, 3000)
    }
  })
}

export const { bootstrap, unmount } = htmlLifecycles // export other lifecycles as-is
